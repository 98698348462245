<template>
  <div
    class="yedai-chat twy-relative "
    ref="chatbot"
    :style="transSendPlaceholder"
    :class="{
      'yedai-chat--errors': hasErrors,
      'yedai-chat--without-bubble': hideCloseBubble,
      'yedai-chat--avatar-bubble': bubbleWithImage
    }"
  >
    <beautiful-chat
      :always-scroll-to-bottom="alwaysScrollToBottom"
      :close="closeChat"
      :colors="colors"
      :is-open="isChatOpen && isLoaded && !hasErrors"
      :message-list="groupedMessageList"
      :on-message-was-sent="onMessageWasSent"
      :showTypingIndicator="innerShowTypingIndicator"
      :open="openChat"
      :participants="participants"
      :placeholder="placeholderText"
      :show-close-button="false"
      :show-launcher="true"
      :style="transSuggestions"
      @onType="removePlaceholder"
      @edit="handleRecording"
    >
      <template v-slot:header>
        <AcceptlegalTerms
          v-if="showRecordinMessages"
          :policy-url="policyUrl"
          @accept="setAcceptRecording"
        />

        <Menu
          v-else
          :end-point="apiUrl"
          :title="titleHeader"
          :language-menu="languageMenu"
          @closeChat="closeChat"
        />
        <InactivityAlert
          v-if="alertMessage.length != 0"
          :message="alertMessage"
          @accept="restartChat"
        />
        <HandOver
          ref="handoverDialog"
          v-if="activeDialog"
          :textMessage="dialogMessage"
          @accept="sendMail"
          @cancel="cancelHandOver"
        />
      </template>

      <template v-slot:user-avatar="{ message }">
        <template v-if="isUserAvatar(message)">
          <slot name="avatarUser" />
        </template>
        <template v-if="isBotAvatar(message)">
          <slot name="avatarBot" />
        </template>
      </template>

      <template v-slot:text-message-body="{ message }">
        <ChatMessage :message="message" :open="isChatOpen" />
      </template>

      <template v-slot:system-message-body="{ message }">
        <ChatMessage :message="{ data: message }" :open="isChatOpen" />
      </template>
    </beautiful-chat>

    <transition name="fade">
      <div
        v-if="hasCta && !isChatOpen && !hasErrors"
        class="yed-bubble-wrapper"
      >
        <slot name="callToAction" />
      </div>
    </transition>
    <transition name="fade">
      <div v-if="hasErrors && !isChatOpen" class="yed-bubble-wrapper">
        <slot name="errorMessage" />
      </div>
    </transition>

    <ChatIconList />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import chatParticipants from "./chatProfiles";
import AcceptlegalTerms from "./legal";
import HandOver from "./handOver";
import Menu from "./menu/index.vue";
import availableColors from "./colors";
import ChatMessage from "./message/chat-message.vue";
import InactivityAlert from "./Inactivity/index.vue";
import _isPlainObject from "lodash/isPlainObject";

import { defaultLanguage } from "@/common/constants.js";

import ChatIconList from "@/components/common/c-icons/iconsChat";

import { marked } from "marked";

import {
  setLocalStorage,
  getLocalStorage,
  removeLocalStorage
} from "./utils/localStorage.js";
import VueCookie from "vue-cookie";

export default {
  name: "Widget",
  components: {
    AcceptlegalTerms,
    Menu,
    ChatMessage,
    ChatIconList,
    InactivityAlert,
    HandOver
  },
  props: {
    titleHeader: {
      type: String,
      default: ""
    },
    endPoint: {
      type: String,
      default: null
    },
    policyUrl: {
      type: String,
      default: null
    },
    storeAutoaccept: {
      type: Boolean,
      default: false
    },
    forceReload: {
      type: Boolean,
      default: false
    },
    lang: {
      type: String,
      default: null
    },
    languageMenu: {
      type: String,
      required: true
    },
    /**
     * If true, hides the close bubble and the bot can only be closed from header.
     * Implemented with styles.
     */
    hideCloseBubble: {
      type: Boolean,
      default: false
    },
    placeholderText: {
      type: String,
      default: "Write something..."
    },
    bubbleWithImage: {
      type: Boolean,
      default: false
    },
    cookiePrefix: {
      type: String,
      default: "yedai"
    },
    zIndex: {
      type: Number,
      default: 0
    },
    /**
     *  Bot's write simulation factor.
     * It takes this number and response's
     * length between 100-500)
     * */
    writeTimeMultiplycityFactor: {
      type: Number,
      default: 10
    },

    backgroundHeaderImage: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      placeholder: false,
      showRecordinMessages: true,
      messageList: getLocalStorage("messageList") || [],
      participants: chatParticipants,
      newMessagesCount: 0,
      isChatOpen: false,
      colors: availableColors,
      alwaysScrollToBottom: true,
      suggestions: [],
      textSuggestion: null,
      hasSuggestions: false,
      apiErrors: [],
      loaded: {
        config: false,
        langConfig: false
      },
      innerShowTypingIndicator: "",
      startConversationTime: Date,
      userInactive: false, //to active inactivity alert
      interval: null, //to clear interval when chat starts. HAce falta?
      alertMessage: "", //to show alert session expired message
      audioIssupportedInBrowser: false, //to show audio button and enable recording if browser supports it
      isTyping: false, //to disable audio button and show send message,
      mediaRecorder: null, //to store de recorder state,
      audio: Audio, //to store audio object
      activeDialog: false, //to show handover popup
      dialogMessage: "", //to save dialog message
      dialogFields: { key: "", type: "" } //to save dialog fields
    };
  },
  created() {
    this.getDefaultConfig();
    this.getDefaultLangConfig();
    console.log("this.cookiePrefix");
    console.log(this.cookiePrefix);
    this.setStoredCookies(this.cookiePrefix);
    if (this.storeAutoaccept) {
      this.setAcceptedPrivacy(true);
    }
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  watch: {
    loadedLang: {
      handler() {
        if (this.lang !== this.currentLang) {
          if (this.optionsLang.includes(this.lang)) {
            this.setCurrentLang(this.lang);
          } else {
            this.setCurrentLang(defaultLanguage);
          }
        }
      }
    }
  },
  methods: {
    ...mapActions("cookies", [
      "setStoredCookies",
      "setAcceptedPrivacy",
      "setChatSession"
    ]),
    ...mapActions("i18n", [
      "setCurrentLang",
      "setTranslations",
      "setLangOptions"
    ]),
    ...mapActions("audio", ["setHasAudio", "setRecording"]),

    removePlaceholder() {
      this.placeholder = true;
      this.setHasAudio(false);
    },
    // Show bot or user avatar in conversation
    isUserAvatar(message) {
      return message.type === "text" && message.author === "me";
    },
    isBotAvatar(message) {
      return message.type === "text" && message.author !== "me";
    },
    // Set cookies
    setAcceptRecording(response) {
      this.setAcceptedPrivacy(response);
      this.showRecordinMessages = !this.acceptedPrivacy;
      this.startChat();
    },
    // Load chatbot default configuration
    getDefaultConfig() {
      const url = this.configUrl;
      this.axios
        .get(url)
        .then(response => {
          this.setTranslations(response.data);
          this.loaded.config = true;
        })
        .catch(error => this.errorHandler(error));
    },
    getDefaultLangConfig() {
      const url = this.configLang;
      this.axios
        .get(url)
        .then(response => {
          this.setLangOptions(response.data[0]);
          this.loaded.langConfig = true;
        })
        .catch(error => this.errorHandler(error));
    },
    errorHandler(error) {
      this.isChatOpen = false;
      this.apiErrors.push(error);
    },

    // START CHAT
    startChat() {
      //review inactivity alert first time...
      const userIsInactive = this.userIsInactive();
      if (userIsInactive) {
        removeLocalStorage("messageList");
      }
      const method = this.chatSession ? "post" : "get";
      const forcedLang = this.lang || this.currentLang;
      //get messages from LocalStorage if exists
      this.messageList = getLocalStorage("messageList") || [];
      /**
       * start chat if we are not waiting for legal term, wating for response from server
       * and there are no messages in localStorage //and chat session is accepted
       **/
      if (
        !this.showTypingIndicator &&
        this.messageList.length === 0 &&
        !this.chatSession &&
        !this.showRecordinMessages
      ) {
        this.showTypingIndicator = true; //show typing indicator
        this.axios({
          url: this.apiUrl,
          method,
          params: {
            store: this.acceptedPrivacy,
            forced_lang: forcedLang
          },
          data: {
            new_conversation: "1",
            session_uid: this.chatSession,
            forced_lang: forcedLang
          }
        }).then(({ data }) => {
          this.setChatSession(data.session_uid);
          this.showTypingIndicator = false;
          this.messageList = [...this.messageList, ...this.parseMessages(data)];
          setLocalStorage("messageList", this.messageList);
        });
      } else {
        this.checkSuggestions(); //check if last message has suggestions
      }
      this.$nextTick(() => {
        this.scrollToLast();
      });
    },

    onMessageWasSent(message) {
      if (this.messageIsntEmpty(message)) {
        this.newMessagesCount++;
        this.messageList = [...this.messageList, message];
        this.textSuggestion = "";
        const forcedLang = this.forcedLang ? this.currentLang : undefined;
        this.showTypingIndicator = true;
        this.startConversationTime = Date.now();

        // Fetch cookies
        const getCookie = name => {
          const value = `; ${document.cookie}`;
          const parts = value.split(`; ${name}=`);
          if (parts.length === 2)
            return parts
              .pop()
              .split(";")
              .shift();
        };
        let headers = {};
        let csrftoken = VueCookie.get("csrftoken");
        if (csrftoken != null) {
          headers = { "X-CSRFToken": csrftoken };
        }
        if (window.location.protocol === "https:") {
          const siteCsrftoken = getCookie("csrftoken");
          const sessionid = getCookie("sessionid");

          // Prepare headers if tokens are available
          if (siteCsrftoken) headers["Site-CSRFToken"] = siteCsrftoken;
          if (sessionid) headers["Authorization"] = sessionid;
        }

        const postOptions = {
          session_uid: this.chatSession,
          text: message.data.text.trim(),
          forced_lang: forcedLang,
          intent: this.suggestions[message.data.text] || undefined,
          input: message.input || undefined
        };
        // this.axios.defaults.withCredentials = true;
        this.axios
          .post(this.apiUrl, postOptions, { headers })
          .then(response => {
            if (!response.data.messages.length == 0) {
              this.emptyCount++;
            }
            this.giveFeedbackAndParseMessages(response.data || response);
            setLocalStorage("messageList", this.messageList); //to have greeting message
            this.$nextTick(() => {
              this.scrollToLast();
            });
          });
      }
    },
    messageIsntEmpty(message) {
      return Boolean(message?.data?.text?.trim());
    },

    /**
     * Calculate max length in messages (card type could have messages)
     * length between 50 and 500 and multiply with writeTimeMulticplycityFactor.
     */
    getWriteSimulationTime(messages) {
      let time = messages
        .map(({ content }) => content?.length || 0)
        .reduce((acc, val) => Math.max(acc, val));
      //time beetween 100 and 500
      time = Math.max(50, time);
      time = Math.min(200, time);
      time = time * this.writeTimeMultiplycityFactor;
      return { simulatedTime: time };
    },

    /**
     * Writing feedback simulation and call method to parse messages to show.
     * First we calculate time relation to length but if response time is greater
     * than 2s, we won't wait */
    giveFeedbackAndParseMessages(data) {
      const { messages } = data;
      let { simulatedTime } = this.getWriteSimulationTime(messages);
      const responseTime = new Date() - this.startConversationTime; //get response time in ms
      const time = Math.max(simulatedTime - responseTime, 0); //avoid negative time
      setTimeout(() => {
        this.showTypingIndicator = false;
        const onlyTextMessages = this.parseMessages(data).filter(
          message => message !== undefined
        );
        this.messageList = [...this.messageList, ...onlyTextMessages];
        setLocalStorage("messageList", this.messageList);
      }, time);
    },

    parseMessages(data) {
      const { messages } = data;
      return messages.map(msg => {
        // Backwards compatibility, todo remove after deploy
        const { content, mime } = msg;
        const {
          suggestions_question,
          has_feedback,
          see_more: seeMore,
          filters,
          session_expired,
          has_voice
        } = data.meta || msg.meta;
        const suggestions = data.meta?.suggestions || msg.suggestions;

        const isCardMessage = mime.startsWith("card/");
        const isAudioMessage = mime.startsWith("audio");
        const isDialogMessage = mime.startsWith("dialog");
        const isActionMessage = mime.startsWith("action");
        this.setHasAudio(this.audioIssupportedInBrowser && has_voice); //to allow next audio message or not
        this.textSuggestion = this.parseSuggestionsQuestion(
          suggestions_question || ""
        );
        this.hasSuggestions = has_feedback || false;
        this.suggestions = {};
        setLocalStorage("textSuggestion", this.textSuggestion);
        /**
         * As we don't have session_expired in back,
         * We calculate the time to show the session expired message (now + 30mins).
         * We use this the diff between this time and now (ideal backend param) to wait untils show the message.
         */
        const sessionExpired = new Date(Date.now() + 30 * 60 * 1000); //or backend return date
        setLocalStorage("session_expired_date", sessionExpired);
        const nextCheckInactivity =
          sessionExpired.getTime() - new Date().getTime(); //diff en ms.
        setTimeout(() => {
          // If time is over, user is inactive,
          if (this.isChatOpen) {
            this.inactivityAlert();
          }
        }, nextCheckInactivity);

        /**
         * Parse different types of messages
         * Audio, Card, Text, Dialog
         * We need Card & text messages to chat
         * we only play once audio message
         * and we show dialog messages in dialog mode
         */

        let messageInfo = {
          author: "user1",
          suggestions: suggestions.map(sug => {
            this.suggestions[sug.content] = sug.intent;
            return sug.content;
          }),
          session_expired: session_expired,
          date: this.startConversationTime
        };
        if (isCardMessage) {
          messageInfo = {
            ...messageInfo,
            data: {
              content: _isPlainObject(content)
                ? {
                    ...content,
                    ...(seeMore ? { seeMore } : {}),
                    ...(filters ? { filters } : {})
                  }
                : content,
              cards: msg.cards,
              text: "",
              mime
            },
            type: "system"
          };
          return messageInfo;
        } else if (isAudioMessage) {
          //play audio
          this.audio = new Audio(msg.content);
          this.audio.play();
          //no return parsed message
        } else if (isDialogMessage) {
          this.dialogMessage = msg.content;
          this.handOverCancelMessage = msg.buttons.cancel;
          this.handOverAcceptMessage = msg.buttons.accept;
          this.activeDialog = true;
          this.$nextTick(function() {
            this.$refs.handoverDialog.setMessage(
              this.dialogMessage,
              data.language
            );
          });
          //no return parsed message to
        } else if (isActionMessage) {
          // Restart conversation
          if (content.actions?.reset) {
            this.restartChat();
          }

          //no return parsed message to
        } else {
          const contentMarkdown = marked.parse(
            content.replace(/<br>/g, "\n").replace(/<br>---<br>/g, "")
          );
          //is text message
          messageInfo = {
            ...messageInfo,
            data: {
              content: _isPlainObject(contentMarkdown)
                ? {
                    ...contentMarkdown,
                    ...(seeMore ? { seeMore } : {}),
                    ...(filters ? { filters } : {})
                  }
                : contentMarkdown,
              cards: msg.cards,
              text: "",
              mime
            },
            type: "text"
          };
          return messageInfo;
        }
      });
    },
    /**
     * Temporary solution around backend sending cards one by one
     *
     * Groups messages so that card messages of the same mime get transformed into a single message
     */
    groupCardMessages(messages) {
      //sometime messages is empty but localstorage is not empty
      return messages.reduce((accumulator, currentMessage) => {
        if (currentMessage.data.mime?.startsWith("card/")) {
          if (currentMessage.data.cards) {
            accumulator.push({
              data: {
                ...currentMessage.data,
                content: currentMessage.data.cards
              },
              suggestions: currentMessage.suggestions,
              type: "system"
            });
          } else {
            // LEGACY FORMAT
            // Keep here until all the bot deployments are updated
            const lastItem = accumulator.slice(-1)[0];

            if (lastItem && lastItem.data.mime === currentMessage.data.mime) {
              lastItem.data.content.push(currentMessage.data.content);
            } else {
              const { data, ...properties } = currentMessage;
              const { content, ...dataProperties } = data;

              accumulator.push({
                ...properties,
                data: {
                  content: [content],
                  ...dataProperties
                }
              });
            }
          }
        } else {
          accumulator.push(currentMessage);
        }
        return accumulator;
      }, []);
    },
    parseSuggestionsQuestion(suggestions_question) {
      let indexEntityStart = suggestions_question.indexOf("&");
      let indexEntityEnd = suggestions_question.indexOf(";", indexEntityStart);
      if (
        indexEntityStart != -1 &&
        indexEntityEnd != -1 &&
        indexEntityStart < indexEntityEnd
      ) {
        let doc = new DOMParser().parseFromString(
          suggestions_question,
          "text/html"
        );
        return doc.documentElement.textContent;
      }
      return suggestions_question;
    },

    /**
     * When chat is open, we check if last message had feedback and load suggestions
     */
    checkSuggestions() {
      if (this.messageList.length > 0) {
        const lastMessage = this.messageList[this.messageList.length - 1];
        if (lastMessage.suggestions.length > 0) {
          this.hasSuggestions = true;
          this.suggestions = {};
          lastMessage.suggestions.forEach(sug => {
            this.suggestions[sug.content] = sug.intent;
          });
          this.textSuggestion = getLocalStorage("textSuggestion") || null;
        }
      }
    },

    /**
     * Find last sent message, and from that msg the next answer.
     */
    scrollToLast() {
      // const compothis.$el
      const { chatbot } = this.$refs;
      if (!chatbot) {
        return;
      }
      const allMessages = chatbot.querySelectorAll(".sc-message .sent");
      if (allMessages.length > 0) {
        const lastMessage = allMessages[allMessages.length - 1];
        lastMessage.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }
    },
    openChat() {
      /**
       * double message if params url. So we checked if already open.
       * If not, we open it but looking if we reolad page
       *  */

      if (!this.isChatOpen) {
        const origin = getLocalStorage("origin") || "";
        const { href } = window.location;
        if (this.forceReload === true || href === origin || origin === "") {
          //reloaded page
          this.setChatSession(null);
          this.messageList = [];
          setLocalStorage("messageList", []);
          setLocalStorage("textSuggestion", null);
        } else {
          //navigating to page
          this.messageList = getLocalStorage("messageList") || [];
          this.textSuggestion = getLocalStorage("textSuggestion") || "";
        }
        setLocalStorage("origin", href); //first time we don't have origin
        this.isChatOpen = true;
        this.startChat();
      }
    },

    /**
     * Close chat should clean chat session and localstorage, and close chat.
     */
    closeChat() {
      //setChatSession without payload to delete session
      this.setChatSession(null);
      removeLocalStorage("messageList");
      this.messageList = [];
      this.isChatOpen = false;
    },
    restartChat() {
      this.userInactive = false;
      this.alertMessage = "";
      this.setChatSession(null);
      removeLocalStorage("messageList");
      this.messageList = [];
      this.startChat();
    },
    /**
     * last message date > session_expired_date, we return true.
     */
    userIsInactive() {
      const now = new Date();
      const sessionExpiredDate =
        new Date(getLocalStorage("session_expired_date")) || null;
      return now >= sessionExpiredDate;
    },
    /**
     * get response intent, show alert, restartChat, clear interval?
     */
    inactivityAlert() {
      const inactivity = this.userIsInactive();

      if (!this.userInactive && inactivity) {
        const lastMessage = this.messageList[this.messageList.length - 1];
        const session_expired = lastMessage.session_expired;
        this.axios
          .post(this.apiUrl, {
            session_uid: this.chatSession,
            text: session_expired.content,
            forced_lang: this.currentLang,
            intent: session_expired.intent || undefined
          })
          .then(response => {
            if (response.data.messages.length !== 0) {
              this.alertMessage = response.data.messages[0].content;
            }
          });
        this.userInactive = true;
      }
    },
    /**
     * Record audio,Send message to api to transcipt, and get response.
  
     */

    handleRecording() {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        //ask user to record audio
        navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
          if (!this.isRecording) {
            if (this.audio.length !== 0) {
              this.audio.pause(); //stop previous play if any .stop() function doesn¡t exi
            }
            this.mediaRecorder = new MediaRecorder(stream);
            /*this is to stop record icon in browser*/
            window.streamReference = stream;

            //start recording and send to userInput boolean. context
            this.setRecording(true);
            this.mediaRecorder.start();

            //stop recording at 15s in case user forgets to stop.
            setTimeout(() => {
              if (this.isRecording) {
                this.mediaRecorder.stop();
                this.setRecording(false);
              }
            }, 15000);

            const audioChunks = [];
            this.mediaRecorder.addEventListener("dataavailable", event => {
              if (event.data.size > 0) {
                audioChunks.push(event.data);
              }
            });

            this.mediaRecorder.addEventListener("stop", () => {
              /* this part is to clear browsers' record icon */
              if (window.streamReference) {
                window.streamReference
                  .getAudioTracks()
                  .forEach(function(track) {
                    track.stop();
                  });
                window.streamReference = null;
              }
              /*now we have audio chunks, we can send to api*/
              const audioBlob = new Blob(audioChunks);
              const apiURL = this.text2SpeechApiURL;
              var formData = new FormData();
              formData.append("audio_stream", audioBlob);
              this.axios
                .post(apiURL, formData, {
                  headers: {
                    "Content-Type": "multipart/form-data"
                  }
                })
                .then(response => {
                  const { data } = response;
                  const { transcript } = data;
                  const message = {
                    author: "me",
                    type: "text", // that works for chatbot list message
                    input: "voice", //that works for tell api to use voice back
                    data: { text: transcript }
                  };
                  this.onMessageWasSent(message);
                });
            });
          } else {
            this.setRecording(false);
            this.mediaRecorder.stop();
          }
        });
      }
    },
    /**
     * Handover to Human feature
     * We show a popup when message has handover active. User can write mail or not.
     * If user write mail,senMail(), we send it to backend and answer is added to messageList.
     * If not, cancelHandOver() is calle, we simulate a message with negative_feedback_hanover intent that is set before .
     * Then, we'll hide popup and continue with messages as usual
     *
     */
    cancelHandOver() {
      //send cancel intent to backend and answer to messageList
      const postOptions = {
        type: "dialog",
        session_uid: this.chatSession,
        text: this.handOverCancelMessage.text,
        intent: this.handOverCancelMessage.intent
      };

      let headers = {};
      let csrftoken = VueCookie.get("csrftoken");
      if (csrftoken != null) {
        headers = { "X-CSRFToken": csrftoken };
      }

      this.axios.post(this.apiUrl, postOptions, { headers }).then(response => {
        if (!response.data.messages.length == 0) {
          this.emptyCount++;
        }
        this.giveFeedbackAndParseMessages(response.data || response);
        setLocalStorage("messageList", this.messageList);
        this.$nextTick(() => {
          this.scrollToLast();
        });
      });
      this.activeDialog = false;
    },
    sendMail(mail) {
      //send mail to backend, add answer to messageList.
      const postOptions = {
        type: "dialog",
        session_uid: this.chatSession,
        text: this.handOverAcceptMessage.text,
        intent: this.handOverAcceptMessage.intent,
        fields: { mail }
      };

      let headers = {};
      let csrftoken = VueCookie.get("csrftoken");
      if (csrftoken != null) {
        headers = { "X-CSRFToken": csrftoken };
      }

      this.axios.post(this.apiUrl, postOptions, { headers }).then(response => {
        if (!response.data.messages.length == 0) {
          this.emptyCount++;
        }
        this.giveFeedbackAndParseMessages(response.data || response);
        setLocalStorage("messageList", this.messageList);
        this.$nextTick(() => {
          this.scrollToLast();
        });
      });
      this.activeDialog = false;
    }
  },

  computed: {
    groupedMessageList() {
      if (this.messageList.length > 0) {
        return this.groupCardMessages(this.messageList);
      }
      return undefined;
    },
    loadedLang() {
      return this.loaded.langConfig && this.lang;
    },
    hasErrors() {
      const error = this.apiErrors;
      if (error.length > 0) {
        console.error("Connection Errors", error);
        console.log(
          "You are having problems with the connection to the API, please revise your credentials or contact the admin"
        );
        return true;
      }

      return false;
    },
    isLoaded() {
      return Object.values(this.loaded).every(value => value);
    },

    transSendPlaceholder() {
      const backgroundHeaderImage = this.backgroundHeaderImage
        ? `url(${this.backgroundHeaderImage}) no-repeat center`
        : "";
      const style =
        `--yed-inputPlaceholder: ${
          this.placeholder ? this.$transMessage : ""
        };` +
        `z-index: ${this.zIndex || 1};` +
        `--yed-header-bg:var(--yed-base-bg) ${backgroundHeaderImage}`;
      return style;
    },
    ...mapGetters("i18n", [
      "trans",
      "currentLang",
      "optionsLang",
      "email_required"
    ]),
    ...mapGetters("cookies", ["forcedLang", "chatSession", "acceptedPrivacy"]),
    ...mapGetters("audio", ["hasAudio", "isRecording"]),
    //TODO: change to base and this.trans.base.send, this.base.title
    $transMessage() {
      return this.trans.writeMessage.send;
    },
    $transTitle() {
      return this.trans.chatBotTitle.title;
    },
    showLegalTerms() {
      return this.showRecordinMessages || !this.acceptedPrivacy;
    },
    hasAcceptedRecording() {
      return !!this.showRecordinMessages;
    },
    hasCta() {
      return !!this.$slots.callToAction;
    },
    baseURL() {
      let yedaiUrl = "";
      if (this.endPoint?.length > 0) {
        yedaiUrl = `${this.endPoint}/`;
      }
      return `${process.env.VUE_APP_API_URL}/api/faq/${yedaiUrl}`;
    },
    text2SpeechApiURL() {
      let yedaiUrl = "";
      if (this.endPoint?.length > 0) {
        yedaiUrl = `${this.endPoint}/`;
      }
      return `${process.env.VUE_APP_API_URL}/voice/${yedaiUrl}speech-to-text/`;
    },
    apiUrl() {
      return `${this.baseURL}conversation/`;
    },
    configUrl() {
      let yedaiUrl = "";
      if (this.endPoint?.length > 0) {
        yedaiUrl = `${this.endPoint}/`;
      }
      return `${process.env.VUE_APP_API_URL}/api/${yedaiUrl}pluginrt/`;
    },
    configLang() {
      let yedaiUrl = "";
      if (this.endPoint?.length > 0) {
        yedaiUrl = `${this.endPoint}/`;
      }
      return `${process.env.VUE_APP_API_URL}/api/${yedaiUrl}botconfigs/`;
    },
    transSuggestions() {
      const hasListOfSuggestions = Object.keys(this.suggestions).length > 0;
      const hasTextSuggestion = this.textSuggestion;
      const isVisible = Boolean(
        this.hasSuggestions && hasListOfSuggestions && hasTextSuggestion
      );
      if (isVisible) {
        return `--suggestionsTitle: "${this.textSuggestion}"`;
      }
      return "--suggestionsTitle: ''";
    },
    /**explicar */
    showTypingIndicator: {
      //getter
      get: function() {
        return this.innerShowTypingIndicator != "";
      },
      // setter
      set: function(isTyping) {
        return (this.innerShowTypingIndicator = isTyping
          ? "writing simulation"
          : "");
      }
    }
  },
  mounted() {
    this.showRecordinMessages = !this.acceptedPrivacy;
    // if browsers doesn't support web audio, we dont show the button
    if (
      MediaRecorder.isTypeSupported("audio/webm") ||
      MediaRecorder.isTypeSupported("audio/ogg")
    )
      this.audioIssupportedInBrowser = true;
  }
};
</script>

<style lang="scss">
.yedai-chat {
  --yed-header-bg: var(--yed-base-bg);
  --yed-header-text: var(--yed-base-text);
  --yed-launcher-bg: var(--yed-base-bg);
  --yed-launcher-text: var(--yed-base-text);
  --yed-messageList-bg: var(--yed-messages-bg);
  --yed-messageList-text: var(--yed-messages-text);
  --yed-userInput-bg: var(--yed-input-bg);
  --yed-userInput-text: var(--yed-input-text);
  --yed-sentMessage-bg: var(--yed-user-bg);
  --yed-sentMessage-text: var(--yed-user-text);
  --yed-receivedMessage-bg: var(--yed-bot-bg);
  --yed-receivedMessage-text: var(--yed-bot-text);
  --yed-receivedSuggestion-text: var(--yed-suggestion-text);
  --yed-cta-bg: var(--yed-messages-bg);
  --yed-cta-text: var(--yed-messages-text);

  .sc-suggestions-row {
    // Added twy-pb-2 because of some error while scoping tailwind base
    @apply twy-flex twy-justify-center twy-items-center twy-pb-2;
    &:before {
      content: var(--suggestionsTitle);
      color: var(--yed-receivedSuggestion-text);
      @apply twy-mr-1 twy-text-sm;
    }
    button {
      // Added twy-ml-2 because of some error while scoping tailwind base
      @apply twy-ml-2;
      border: none;
    }
  }
  .sc-user-input--text {
    @apply twy-mx-4 twy-px-0 twy-text-left;
    &:empty:before {
      color: var(--yed-userInput-text);
    }
  }
  .sc-user-input--buttons {
    @apply twy-mx-4 twy-relative twy-right-0;
  }

  .sc-chat-window {
    @apply twy-rounded-2xl #{!important};
  }
  .sc-user-input {
    @apply twy-rounded-b-2xl #{!important};
  }
  .sc-header {
    @apply twy-px-4 twy-rounded-t-2xl twy-static twy-bg-cover #{!important};
  }
  .sc-message-list {
    @apply twy-pb-4 #{!important};
  }
  .sc-message {
    @apply twy-mx-4 twy-w-auto;
    &--content {
      word-break: break-word;

      .sc-message--text {
        @apply twy-rounded-2xl twy-shadow-lg twy-text-left twy-py-2 twy-px-4;
      }

      .sc-message--system {
        @apply twy-p-0 twy-opacity-100 twy-w-full twy-bg-transparent #{!important};
      }
    }
  }
  .sc-message--content.received {
    .sc-message--text {
      @apply twy-rounded-tl-none;
    }
  }
  .sc-message--content.sent {
    div:first-child {
      order: 2;
    }
    .sc-message--text {
      @apply twy-rounded-br-none;
      order: 1;
    }
  }

  .yed-bubble-wrapper {
    position: fixed;
    right: 105px;
    bottom: 25px;
    left: 25px;
  }
  .sc-user-input--text::before {
    content: var(--yed-inputPlaceholder, attr(placeholder));
  }
  &.yedai-chat--errors {
    @apply twy-pointer-events-none;
    opacity: 0.75;
    --yed-base-bg: #c0382b;
  }
  &.yedai-chat--avatar-bubble {
    .yed-bubble-wrapper {
      right: 115px;
      bottom: 35px;
    }
    .sc-launcher:not(.opened) {
      --yed-launcher-bg: none;
      background-image: var(--yed-bubble-avatar, "none");
      background-size: contain;
      width: 80px;
      height: 80px;
      img {
        display: none !important;
      }
    }
  }
  &.yedai-chat--without-bubble {
    .sc-chat-window {
      bottom: 30px;
    }
    .sc-launcher.opened {
      visibility: hidden;
    }
  }
  .yedai-chat__inactive-box {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(white, 0.5);
    // background-color: rgba(var(--yed-base-bg), 0.5);
    position: fixed;
    bottom: 30px;
    right: 25px;
    width: 370px;
    height: 590px; //it should be as chatbot height
  }
  .yedai-chat__inactive-content {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: var(--yed-base-bg);
    border-radius: 10px;
    border-color: 1px solid red;
  }
  .yedai-chat__inactive-button-button {
    background-color: var(--yed-userInput-bg);
    border-radius: 10px;
    color: var(--yed-base-text);
    font-size: 1.2rem;
    padding: 3px 10px;
    cursor: pointer;
  }
  .yedai-chat--showAlert {
    display: none;
  }
}
@media (max-width: 450px) {
  .yedai-chat {
    &.yedai-chat--without-bubble {
      .sc-chat-window {
        bottom: 0;
      }
    }
  }
}
</style>
